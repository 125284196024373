<template>
  <div class="settings-header">
    <h1 class="page-title">
      <!--      <woot-sidemenu-icon v-if="showSidemenuIcon" />-->
      <back-button
        v-if="
          showBackButton ||
            this.$route.name === 'agent_bots_webhook_clustered_questions'
        "
        :button-label="backButtonLabel"
        :back-url="backUrl"
      />
      <fluent-icon v-if="icon" :icon="icon" :class="iconClass" />
      <slot />
      <span
        :class="isAIAssistantPage ? 'cursor-pointer' : ''"
        @click="handleBack"
      >
        {{ overrideHeaderTitle || headerTitle }}
      </span>
    </h1>
    <ClusteringTopLeft v-if="isClusteringQuestionsPage" />
    <router-link
      v-if="showNewButton && isAdmin"
      :to="buttonRoute"
      class="button success button--fixed-top"
      :data-testid="buttonRoute + '-button'"
    >
      <fluent-icon icon="add-circle" />
      <span class="button__content">
        {{ buttonText }}
      </span>
    </router-link>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BackButton from '../../../components/widgets/BackButton';
import adminMixin from '../../../mixins/isAdmin';
import { AI_ASSISTANT_ROUTE_NAMES } from './constants';
import ClusteringTopLeft from './agentBots/components/ClusteringTopLeft.vue';

export default {
  components: {
    BackButton,
    ClusteringTopLeft,
  },
  mixins: [adminMixin],
  props: {
    headerTitle: {
      default: '',
      type: String,
    },
    buttonRoute: {
      default: '',
      type: String,
    },
    buttonText: {
      default: '',
      type: String,
    },
    icon: {
      default: '',
      type: String,
    },
    showBackButton: { type: Boolean, default: false },
    showNewButton: { type: Boolean, default: false },
    backUrl: {
      type: [String, Object],
      default: '',
    },
    backButtonLabel: {
      type: String,
      default: '',
    },
    showSidemenuIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      counter: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      agentBotUIFlags: 'agentBots/getUIFlags',
    }),
    iconClass() {
      return `icon ${this.icon} header--icon`;
    },
    isAIAssistantPage() {
      return this.$route.fullPath.includes('agent-bots');
    },
    isClusteringQuestionsPage() {
      return this.$route.name === 'agent_bots_webhook_clustered_questions';
    },
    overrideHeaderTitle() {
      const hasExtraInfo = [
        'agent_bots_webhook_train',
        'agent_bots_webhook_edit',
        'agent_bots_webhook_train_notea',
      ].includes(this.$route.name);
      const isClusteringQuestionsPage =
        this.$route.name === 'agent_bots_webhook_clustered_questions';

      if (isClusteringQuestionsPage) {
        return this.$t(this.$store.state.route.meta.title);
      }
      if (hasExtraInfo) {
        return this.agentBotUIFlags?.activeAgentBot?.name;
      }
      return null;
    },
  },
  methods: {
    handleBack() {
      const isClusteringQuestionsPage =
        this.$route.name === 'agent_bots_webhook_clustered_questions';
      if (isClusteringQuestionsPage) {
        return;
      }
      if (this.isAIAssistantPage) {
        this.counter += 1;
        const accountId = this.$route.params.accountId;
        const botId = this.$route.params.botId;
        const routeName = this.$route.name;
        const routeMapping = {
          [AI_ASSISTANT_ROUTE_NAMES.AGENT_BOTS_WEBHOOK_TRAIN_NOTEA]: `/app/accounts/${accountId}/agent-bots/webhook/${botId}/train`,
          [AI_ASSISTANT_ROUTE_NAMES.AGENT_BOTS_WEBHOOK_TRAIN]: `/app/accounts/${accountId}/agent-bots`,
          [AI_ASSISTANT_ROUTE_NAMES.AGENT_BOTS_WEBHOOK_NEW]: `/app/accounts/${accountId}/agent-bots`,
          [AI_ASSISTANT_ROUTE_NAMES.AGENT_BOTS_WEBHOOK_EDIT]: `/app/accounts/${accountId}/agent-bots`,
        };
        const defaultPath = `/app/accounts/${accountId}/agent-bots`;
        const targetPath = routeMapping[routeName] || defaultPath;
        // TODO:this logic is temporary, it will change according to the new design
        this.$router.push({
          path: targetPath,
          replace: true,
          query: {
            is_rule_based:
              targetPath === `/app/accounts/${accountId}/agent-bots`
                ? this.counter
                : 0,
          },
        });
      }
    },
  },
};
</script>
