import Vue from 'vue';
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import AgentBotsAPI from '../../api/agentBots';
import InboxesAPI from '../../api/inboxes';
import {
  getClusters,
  getQuestions,
  deleteCluster,
  deleteQuestion,
  validateQuestion,
  validateCluster,
  setClusterDocId,
  upsert,
} from '../../api/bot/index';
import { throwErrorMessage } from '../utils/api';

export const state = {
  records: [],
  clusters: [],
  questions: [],
  currentEditingClusterId: null,
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isFetchingAgentBot: false,
    isUpserting: false,
    isSettingAgentBot: false,
    isDisconnecting: false,
    isFetchingClusters: false,
    hasOutdatedClusters: false,
    fetchingQuestions: null,
    clusterToDelete: null,
    clusterQuestionToDelete: null,
    settingClusterSource: null,
    isClusteringQuestions: false,
    isValidatingCluster: false,
    isValidatingQuestion: false,
  },
  agentBotInbox: {},
};

export const getters = {
  getBots($state) {
    return $state.records;
  },
  getKnowledgeBasedBots($state) {
    return $state.records.filter(bot => bot.description !== '___typebot___');
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getBot: $state => botId => {
    const [bot] = $state.records.filter(record => record.id === Number(botId));
    return bot || {};
  },
  getActiveAgentBot: $state => inboxId => {
    const associatedAgentBotId = $state.agentBotInbox[Number(inboxId)];
    return getters.getBot($state)(associatedAgentBotId);
  },
  getClusters: $state => $state.clusters,
  getQuestions: $state => clusterId => {
    const questions = [...$state.questions];
    return questions.filter(
      question => question.cluster_id === clusterId && question.status !== 1
    );
  },
  getClusterSourceId: $state => clusterId => {
    const currentCluster = $state.clusters.find(
      cluster => cluster.id === clusterId
    );
    if (currentCluster && currentCluster.source) {
      return currentCluster.source.document_id;
    }
    return '';
  },
  getCurrentEditingClusterId: $state => {
    return $state.currentEditingClusterId;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isFetching: true });
    try {
      const response = await AgentBotsAPI.get();
      commit(types.SET_AGENT_BOTS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, agentBotObj) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isCreating: true });
    try {
      const response = await AgentBotsAPI.create(agentBotObj);
      commit(types.ADD_AGENT_BOT, response.data);
      return response.data;
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isCreating: false });
    }
    return null;
  },
  update: async ({ commit }, { id, ...agentBotObj }) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isUpdating: true });
    try {
      const response = await AgentBotsAPI.update(id, agentBotObj);
      commit(types.EDIT_AGENT_BOT, response.data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isDeleting: true });
    try {
      await AgentBotsAPI.delete(id);
      commit(types.DELETE_AGENT_BOT, id);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isDeleting: false });
    }
  },
  show: async ({ commit }, id) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isFetchingItem: true });
    try {
      const { data } = await AgentBotsAPI.show(id);
      commit(types.ADD_AGENT_BOT, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isFetchingItem: false });
    }
  },

  fetchAgentBotInbox: async ({ commit }, inboxId) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isFetchingAgentBot: true });
    try {
      const { data } = await InboxesAPI.getAgentBot(inboxId);
      const { agent_bot: agentBot = {} } = data || {};
      commit(types.SET_AGENT_BOT_INBOX, {
        agentBotId: agentBot.id,
        inboxId,
      });
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isFetchingAgentBot: false });
    }
  },

  fetchClusters: async ({ commit }, { accountId, agentBotId }) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isFetchingClusters: true });
    try {
      const { data } = await getClusters({
        path: { account_id: accountId, agentbot_id: agentBotId },
      });
      commit(types.SET_CLUSTERS, data.clusters);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isFetchingClusters: false });
    }
  },

  backGroundFetchClusters: async (
    { commit, state: $state },
    { accountId, agentBotId }
  ) => {
    if ($state.uiFlags.hasOutdatedClusters) return;

    try {
      const { data } = await getClusters({
        path: { account_id: accountId, agentbot_id: agentBotId },
      });
      if (JSON.stringify(data.clusters) !== JSON.stringify($state.clusters)) {
        commit(types.SET_AGENT_BOT_UI_FLAG, { hasOutdatedClusters: true });
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  },

  fetchQuestions: async (
    { commit },
    { accountId, agentBotId, clusterId, sorting }
  ) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { fetchingQuestions: clusterId });
    try {
      const { data } = await getQuestions({
        path: { account_id: accountId, agentbot_id: agentBotId },
        query: { cluster_id: clusterId, order: sorting },
      });
      commit(types.SET_QUESTIONS, data.questions);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, {
        fetchingQuestions: null,
      });
    }
  },

  setClusterDocId: async (
    { commit },
    { accountId, agentBotId, clusterId, documentId }
  ) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { settingClusterSource: clusterId });
    try {
      await setClusterDocId({
        path: {
          account_id: accountId,
          agentbot_id: agentBotId,
          cluster_id: clusterId,
        },
        query: { document_id: documentId },
      });
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, {
        settingClusterSource: null,
      });
    }
  },

  setDocumentIdOnCluster: async ({ commit }, { clusterId, documentId }) => {
    commit(types.SET_DOCUMENT_ID_TO_CLUSTER, { clusterId, documentId });
  },
  deleteCluster: async (
    { commit, dispatch },
    { accountId, agentBotId, clusterId }
  ) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { clusterToDelete: clusterId });
    try {
      await deleteCluster({
        path: { account_id: accountId, agentbot_id: agentBotId },
        query: { cluster_id: clusterId },
      });
      commit(types.DELETE_CLUSTER, clusterId);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { clusterToDelete: null });
      dispatch('fetchClusters', { accountId, agentBotId });
    }
  },

  deleteQuestion: async (
    { commit },
    { accountId, agentBotId, questionId, clusterId }
  ) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, {
      clusterQuestionToDelete: clusterId,
    });
    try {
      const result = await deleteQuestion({
        path: { account_id: accountId, agentbot_id: agentBotId },
        query: { question_id: questionId },
      });
      const status = result.data.status;
      if (status === 'success') {
        commit(types.DELETE_QUESTION, questionId);
        commit(types.REDUCE_CLUSTER_QUESTION_COUNT, { cluster_id: clusterId });
      } else {
        throwErrorMessage('Error deleting question');
      }
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { clusterQuestionToDelete: null });
    }
  },

  validateQuestion: async (
    { commit },
    { accountId, agentBotId, questionId }
  ) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isValidatingQuestion: true });
    try {
      await validateQuestion({
        path: { account_id: accountId, agentbot_id: agentBotId },
        query: { question_id: questionId },
      });
      commit(types.VALIDATE_QUESTION, questionId);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, {
        isValidatingQuestion: false,
      });
    }
  },

  validateCluster: async ({ commit }, { clusterId, accountId, agentBotId }) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isValidatingCluster: true });
    try {
      await validateCluster({
        path: { account_id: accountId, agentbot_id: agentBotId },
        query: { cluster_id: clusterId },
      });
      commit(types.VALIDATE_CLUSTER, clusterId);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, {
        isValidatingCluster: false,
      });
    }
  },

  upsertDocument: async ({ commit }, { accountId, agentbotId, body }) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isUpserting: true });
    try {
      await upsert({
        path: { account_id: accountId, agentbot_id: agentbotId },
        body,
      });
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isUpserting: false });
    }
  },

  setAgentBotInbox: async ({ commit }, { inboxId, botId }) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isSettingAgentBot: true });
    try {
      await InboxesAPI.setAgentBot(inboxId, botId);
      commit(types.SET_AGENT_BOT_INBOX, { agentBotId: botId, inboxId });
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isSettingAgentBot: false });
    }
  },

  disconnectBot: async ({ commit }, { inboxId }) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { isDisconnecting: true });
    try {
      await InboxesAPI.setAgentBot(inboxId, null);
      commit(types.SET_AGENT_BOT_INBOX, { agentBotId: '', inboxId });
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_AGENT_BOT_UI_FLAG, { isDisconnecting: false });
    }
  },
  setUIActiveBot: async ({ commit }, activeAgentBot) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, { activeAgentBot });
  },
  setUiFlags: async ({ commit }, uiFlags) => {
    commit(types.SET_AGENT_BOT_UI_FLAG, uiFlags);
  },
  setCurrentEditingClusterId: async ({ commit }, clusterId) => {
    commit(types.SET_CURRENT_EDITING_CLUSTER_ID, {
      currentEditingClusterId: clusterId,
    });
  },
};

export const mutations = {
  [types.SET_AGENT_BOT_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.ADD_AGENT_BOT]: MutationHelpers.setSingleRecord,
  [types.SET_AGENT_BOTS]: MutationHelpers.set,
  [types.EDIT_AGENT_BOT]: MutationHelpers.update,
  [types.DELETE_AGENT_BOT]: MutationHelpers.destroy,
  [types.SET_AGENT_BOT_INBOX]($state, { agentBotId, inboxId }) {
    Vue.set($state.agentBotInbox, inboxId, agentBotId);
  },
  [types.SET_CLUSTERS]($state, data) {
    $state.clusters = data;
  },
  [types.SET_QUESTIONS]($state, data) {
    // reset this cluster saved questions
    const clusterId = data[0].cluster_id;
    const filteredQuestions = $state.questions.filter(
      q => q.cluster_id !== clusterId
    );

    $state.questions = [...filteredQuestions, ...data];
  },
  [types.VALIDATE_QUESTION]($state, questionId) {
    const questionIndex = $state.questions.findIndex(q => q.id === questionId);
    if (questionIndex === -1) {
      return;
    }

    $state.questions[questionIndex].status = 1;
  },
  [types.DELETE_CLUSTER]($state, clusterId) {
    $state.records = $state.records.filter(
      record => record.cluster_id !== clusterId
    );
    $state.questions = $state.questions.filter(
      question => question.cluster_id !== clusterId
    );
  },
  [types.DELETE_QUESTION]($state, questionId) {
    $state.records = $state.records.filter(record => record.id !== questionId);
  },
  [types.SET_CURRENT_EDITING_CLUSTER_ID]($state, { currentEditingClusterId }) {
    $state.currentEditingClusterId = currentEditingClusterId;
  },
  [types.SET_DOCUMENT_ID_TO_CLUSTER]($state, { clusterId, documentId }) {
    $state.clusters = $state.clusters.map(cluster => {
      if (cluster.id === clusterId) {
        return {
          ...cluster,
          source: {
            source: 'notea',
            document_id: documentId,
          },
        };
      }
      return cluster;
    });
  },
  [types.REDUCE_CLUSTER_QUESTION_COUNT]($state, { cluster_id }) {
    $state.clusters = $state.clusters.map(cluster => {
      if (cluster.id === cluster_id && cluster.question_count > 0) {
        return {
          ...cluster,
          question_count: cluster.question_count - 1,
        };
      }
      return cluster;
    });
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
