import axios from 'axios';
import auth from '../auth';

export function upsert(params) {
  const authToken = auth.getAuthToken();
  return axios.post(
    `/svc/bot/v1/docs/accounts/${params.path.account_id}/agentbots/${params.path.agentbot_id}/upsert`,
    params.body,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: authToken,
      },
    }
  );
}

export function upsertSchedule(params) {
  const authToken = auth.getAuthToken();
  return axios.post(
    `/svc/bot/v1/docs/accounts/${params.path.account_id}/agentbots/${params.path.agentbot_id}/upsert/schedule`,
    {
      params: params.query,
    },
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: authToken,
      },
    }
  );
}

export function upsertActorSchedule(params) {
  const authToken = auth.getAuthToken();
  return axios.post(
    `/svc/bot/v1/docs/accounts/${params.path.account_id}/agentbots/${params.path.agentbot_id}/upsert/actors/schedule`,
    {
      params: params.query,
    },
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: authToken,
      },
    }
  );
}

export function queryMain(params) {
  const authToken = auth.getAuthToken();
  return axios.post(
    `/svc/bot/v1/docs/query`,
    {
      params: params.query,
    },
    {
      headers: {
        authorization: authToken,
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
}

export function actorJobs(params) {
  const authToken = auth.getAuthToken();
  return axios.get(
    `/svc/bot/v1/docs/accounts/${params.path.account_id}/agentbots/${params.path.agentbot_id}/actors/jobs`,
    { params: params.query, headers: { authorization: authToken } }
  );
}

export function deleteSchedule(params) {
  const authToken = auth.getAuthToken();
  return axios.delete(
    `/svc/bot/v1/docs/accounts/${params.path.account_id}/agentbots/${params.path.agentbot_id}/delete`,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: authToken,
      },
      params: params.query,
    }
  );
}

export function upsertPortalDocs(params) {
  const authToken = auth.getAuthToken();
  return axios.post(
    `/svc/bot/v1/docs/accounts/${params.path.account_id}/portals/{portal_id}/upsert`,
    {
      params: params.query,
    },
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: authToken,
      },
    }
  );
}

export function upsertPortalSchedule(params) {
  const authToken = auth.getAuthToken();
  return axios.post(
    `/svc/bot/v1/docs/accounts/${params.path.account_id}/portals/{portal_id}/upsert/schedule`,
    {
      params: params.query,
    },
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: authToken,
      },
    }
  );
}

export function deletePortalDocsSchedule(params) {
  const authToken = auth.getAuthToken();
  return axios.delete(
    `/svc/bot/v1/docs/accounts/${params.path.account_id}/portals/${params.path.portal_id}/delete`,
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: authToken,
      },
      params: params.query,
    }
  );
}

export function actors() {
  const authToken = auth.getAuthToken();
  return axios.get(`/svc/bot/v1/docs/actors`, {
    headers: { 'Content-Type': 'application/json', authorization: authToken },
  });
}

export function handleAgentbotEvents(params) {
  const authToken = auth.getAuthToken();
  return axios.post(
    `/svc/bot/v1/events/agentbots/${params.path.agentbot_id}`,
    {
      params: params.query,
    },
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: authToken,
      },
    }
  );
}

export function handleNotifications(params) {
  const authToken = auth.getAuthToken();
  return axios.post(
    `/svc/bot/v1/events/swarm`,
    {
      params: params.query,
    },
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: authToken,
      },
    }
  );
}

export function testHandler(params) {
  const authToken = auth.getAuthToken();
  return axios.post(
    `/svc/bot/v1/events/agentbots/test`,
    {
      params: params.query,
    },
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: authToken,
      },
    }
  );
}

export function assistants() {
  const authToken = auth.getAuthToken();
  return axios.get(`/svc/bot/v1/agentbots/assistants`, {
    headers: { 'Content-Type': 'application/json', authorization: authToken },
  });
}

export function agentbotModels() {
  const authToken = auth.getAuthToken();
  return axios.get(`/svc/bot/v1/agentbots/models`, {
    headers: { 'Content-Type': 'application/json', authorization: authToken },
  });
}

export function getAssistant(params) {
  const authToken = auth.getAuthToken();
  return axios.get(
    `/svc/bot/v1/agentbots/accounts/${params.path.account_id}/assistants/{assistant_id}`,
    {
      params: params.query,
      headers: {
        authorization: authToken,
      },
    }
  );
}

export function upsertAssistant(params) {
  const authToken = auth.getAuthToken();
  return axios.put(
    `/svc/bot/v1/agentbots/accounts/${params.path.account_id}/assistants/{assistant_id}`,
    {
      params: params.query,
    },
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: authToken,
      },
    }
  );
}

export function deleteAssistant(params) {
  const authToken = auth.getAuthToken();
  return axios.delete(
    `/svc/bot/v1/agentbots/accounts/${params.path.account_id}/assistants/{assistant_id}`,
    {
      params: params.query,
      headers: {
        authorization: authToken,
      },
    }
  );
}

export function getAllAssistants(params) {
  const authToken = auth.getAuthToken();
  return axios.get(
    `/svc/bot/v1/agentbots/accounts/${params.path.account_id}/assistants`,
    {
      params: params.query,
      headers: {
        authorization: authToken,
      },
    }
  );
}

export function trainAgentbot(params) {
  const authToken = auth.getAuthToken();
  return axios.post(
    `/svc/bot/v1/agentbots/accounts/${params.path.account_id}/agentbots/${params.path.agentbot_id}/train`,
    {
      params: params.query,
    },
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: authToken,
      },
    }
  );
}

export function customLlmOpenaiSseHandler(params) {
  const authToken = auth.getAuthToken();
  return axios.post(
    `/svc/bot/v1/openai-sse/accounts/${params.path.account_id}/agentbots/${params.path.agentbot_id}/chat/completions`,
    {
      params: params.query,
    },
    {
      headers: { 'Content-Type': 'application/json', authorization: authToken },
    }
  );
}

export function fillInputForm(params) {
  const authToken = auth.getAuthToken();
  return axios.post(
    `/svc/bot/v1/fill-input-form`,
    {
      params: params.query,
    },
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: authToken,
      },
    }
  );
}

export function getClusters(params) {
  const authToken = auth.getAuthToken();
  return axios.get(
    `/svc/bot/v1/questions/accounts/${params.path.account_id}/agentbots/${params.path.agentbot_id}/cluster`,
    {
      params: params.query,
      headers: {
        authorization: authToken,
      },
    }
  );
}

export function clusterQuestions(params) {
  const authToken = auth.getAuthToken();
  return axios.post(
    `/svc/bot/v1/questions/accounts/${params.path.account_id}/agentbots/${params.path.agentbot_id}/cluster`,
    {
      params: params.query,
    },
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: authToken,
      },
    }
  );
}

export function deleteCluster(params) {
  const authToken = auth.getAuthToken();
  return axios.delete(
    `/svc/bot/v1/questions/accounts/${params.path.account_id}/agentbots/${params.path.agentbot_id}/cluster`,
    {
      params: params.query,
      headers: {
        authorization: authToken,
      },
    }
  );
}

export function processConversation(params) {
  const authToken = auth.getAuthToken();
  return axios.post(
    `/svc/bot/v1/questions/accounts/${params.path.account_id}/agentbots/${params.path.agentbot_id}/conversations/${params.path.conversation_id}/process`,
    {
      params: params.query,
    },
    {
      headers: { 'Content-Type': 'application/json', authorization: authToken },
    }
  );
}

export function getQuestions(params) {
  const authToken = auth.getAuthToken();
  return axios.get(
    `/svc/bot/v1/questions/accounts/${params.path.account_id}/agentbots/${params.path.agentbot_id}`,
    {
      params: params.query,
      headers: {
        authorization: authToken,
      },
    }
  );
}

export function deleteQuestion(params) {
  const authToken = auth.getAuthToken();
  return axios.delete(
    `/svc/bot/v1/questions/accounts/${params.path.account_id}/agentbots/${params.path.agentbot_id}`,
    {
      params: params.query,
      headers: {
        authorization: authToken,
      },
    }
  );
}

export function validateQuestion(params) {
  const authToken = auth.getAuthToken();
  return axios.post(
    `/svc/bot/v1/questions/accounts/${params.path.account_id}/agentbots/${params.path.agentbot_id}/validate`,
    {
      params: params.query,
    },
    {
      headers: { 'Content-Type': 'application/json', authorization: authToken },
    }
  );
}

export function validateCluster(params) {
  const authToken = auth.getAuthToken();
  return axios.post(
    `/svc/bot/v1/questions/accounts/${params.path.account_id}/agentbots/${params.path.agentbot_id}/cluster/validate?cluster_id=${params.query.cluster_id}`,
    {},
    {
      headers: { 'Content-Type': 'application/json', authorization: authToken },
    }
  );
}

export function setClusterDocId(params) {
  const authToken = auth.getAuthToken();
  return axios.put(
    `/svc/bot/v1/questions/accounts/${params.path.account_id}/agentbots/${params.path.agentbot_id}/cluster/${params.path.cluster_id}/source`,
    {
      source: 'notea',
      document_id: params.query.document_id,
    },
    {
      headers: { 'Content-Type': 'application/json', authorization: authToken },
    }
  );
}
