<template>
  <div class="spacer">
    <select
      v-if="agentBots.length > 1"
      v-model="selectedBotId"
      :multiple="false"
      :value="$route.params.botId"
    >
      <option
        v-for="agentBot in agentBots"
        :key="agentBot.id"
        :value="agentBot.id"
      >
        {{ agentBot.name }}
      </option>
    </select>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      selectedBotId: {},
    };
  },
  computed: {
    ...mapGetters({
      agentBots: 'agentBots/getBots',
    }),
  },
  watch: {
    selectedBotId(value) {
      if (this.$route.params.botId === value) {
        return;
      }

      this.$router.push({
        name: 'agent_bots_webhook_clustered_questions',
        params: {
          botId: value,
          botToken: this.$route.params.botToken,
        },
      });
    },
    agentBots(value) {
      if (value.length === 1) {
        this.selectedBotId = value[0].id;
      } else if (value.length > 1) {
        this.selectedBotId = this.$route.params.botId;
      }
    },
  },
  mounted() {
    this.$store.dispatch('agentBots/get');
  },
};
</script>

<style lang="scss" scoped>
select {
  height: 3.3rem;
  line-height: 1.2rem;
  margin: 8px;
  background-color: var(--s-50);
  width: 120px;
}
</style>
