import SettingsContent from '../Wrapper';
const Bot = () => import('./Index.vue');
const CsmlEditBot = () => import('./csml/Edit.vue');
const CsmlNewBot = () => import('./csml/New.vue');
const WebhookEditBot = () => import('./webhook/Edit.vue');
const WebhookNewBot = () => import('./webhook/New.vue');
const WebhookTrainBot = () => import('./webhook/Train.vue');
const ClusteringPage = () => import('./webhook/Clustering.vue');
const WebhookTrainBotNotea = () => import('./webhook/Notea.vue');
import { frontendURL } from '../../../../helper/URLHelper';
import { getURLMetaData } from 'shared/helpers/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/agent-bots'),
      roles: ['administrator'],
      component: SettingsContent,
      props: {
        headerTitle: 'AGENT_BOTS.HEADER',
        icon: 'bot',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          name: 'agent_bots',
          component: Bot,
          roles: ['administrator'],
          meta: getURLMetaData('PAGE_TITLES.AI_ASSISTANTS.MAIN'),
        },
        {
          path: 'csml/new',
          name: 'agent_bots_csml_new',
          component: CsmlNewBot,
          roles: ['administrator'],
          meta: getURLMetaData('PAGE_TITLES.AI_ASSISTANTS.NEW_CSML'),
        },
        {
          path: 'csml/:botId',
          name: 'agent_bots_csml_edit',
          component: CsmlEditBot,
          roles: ['administrator'],
          meta: getURLMetaData('PAGE_TITLES.AI_ASSISTANTS.EDIT_CSML'),
        },
        {
          path: 'webhook/new',
          name: 'agent_bots_webhook_new',
          component: WebhookNewBot,
          roles: ['administrator'],
          meta: getURLMetaData('PAGE_TITLES.AI_ASSISTANTS.NEW'),
        },
        {
          path: 'webhook/:botId',
          name: 'agent_bots_webhook_edit',
          component: WebhookEditBot,
          roles: ['administrator'],
          meta: getURLMetaData('PAGE_TITLES.AI_ASSISTANTS.EDIT'),
        },
        {
          path: 'webhook/:botId/train',
          name: 'agent_bots_webhook_train',
          component: WebhookTrainBot,
          roles: ['administrator'],
          meta: getURLMetaData('PAGE_TITLES.AI_ASSISTANTS.TRAIN'),
        },
        {
          path: 'webhook/:botId/train/notea/:botToken',
          name: 'agent_bots_webhook_train_notea',
          component: WebhookTrainBotNotea,
          roles: ['administrator'],
          meta: getURLMetaData('PAGE_TITLES.AI_ASSISTANTS.TRAIN_NOTEA'),
        },
        {
          path: 'webhook/:botId/clustered-questions/:botToken',
          name: 'agent_bots_webhook_clustered_questions',
          component: ClusteringPage,
          roles: ['administrator'],
          meta: getURLMetaData(
            'PAGE_TITLES.AI_ASSISTANTS.CLUSTERING_QUESTIONS'
          ),
        },
      ],
    },
  ],
};
